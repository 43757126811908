
export const rawTrpcRequest = (input, options = {}) => {
  const config = useRuntimeConfig()
  const baseUrl = config.public.API_URL;
  let url = `${baseUrl}/${options.route}.${options.procedure}`;

  const object = {
    json: input
  }

  if (options.method === 'GET') {
    url = `${url}?input=${encodeURIComponent(JSON.stringify(object))}`
    return $fetch(url)
  }

  return $fetch(url, {
    method: options.method,
    body: JSON.stringify(object)
  })
}
